import {
  DiscoverResult,
  ErrorResponse,
  ISdkManagedPaymentIntent,
  loadStripeTerminal,
  Reader,
  SdkManagedPaymentIntent,
  Terminal
} from '@stripe/terminal-js';
import {
  IStripePaymentHandler,
  IStripeTerminalDialogState,
  TStripeDialogContent
} from 'components/Purchase/Stripe/types';
import {DEVELOPMENT} from 'config';
import {STRIPE_TEST_CARD_NUMBER, TERMINAL_PROCESSING_PAYMENT_STATUS} from 'constants/payments';
import {PURCHASE_TERMINAL_INIT_ERROR, STRIPE_COLLECT_ERROR_CANCELED_CODE} from 'constants/purchase';
import usePaymentGatewayClient from 'hooks/axios/usePaymentGatewayClient';
import {useCallback, useMemo, useRef} from 'react';
import {useRecoilValue} from 'recoil';
import {stripeLocationIdState} from 'state/atoms/purchase';
import {useCardPaymentFlow} from './useCardPaymentFlow';

interface Input {
  cancelFlowPayments: IStripePaymentHandler['cancelFlowPayments'];
}

export interface IUseStripeTerminalFlowOutput {
  dialogInfo: TStripeDialogContent;
  isCancelVisible: boolean;
  isFooterVisible: boolean;
  isSimulatedTerminal: boolean;
  showFallbackOptions: boolean;
  reader: IStripeTerminalDialogState['reader'];
  readers: IStripeTerminalDialogState['readers'];
  status: IStripeTerminalDialogState['status'];
  attemptPaymentWithSelectedTerminal: (reader: Reader) => Promise<void>;
  runTerminalPayment: () => Promise<void>;
  resetReaderIfConnected: () => Promise<void>;
  onClickDialogCancel: () => Promise<void>;
  onLocalSelectReader: (reader: Reader) => void;
  onLocalSelectCardNumber: (testCardNumber: STRIPE_TEST_CARD_NUMBER) => void;
}

const useStripeTerminalFlow = ({cancelFlowPayments}: Input): IUseStripeTerminalFlowOutput => {
  const {paymentGatewayClient} = usePaymentGatewayClient();
  const terminalRef = useRef<Terminal>();

  const {
    amountOfCurrentStep,
    currentStep,
    currentStepIntentRequiresCapture,
    hasCardPaymentSteps,
    getCardPaymentFlowInfo,
    updateCurrentCardPaymentDialogState,
    updateCurrentCardPaymentIntent
  } = useCardPaymentFlow();
  const {status, loadingMessage, errorMessage, reader, readers, onCardNumberSelect, onReaderSelect} =
    (currentStep?.state as IStripeTerminalDialogState) ?? {};

  const isConfirmVisible = status === 'SUCCESS';
  // improve robustness - replace !currentStepIntentRequiresCapture with === false
  const showFallbackOptions = status === 'ERROR' && hasCardPaymentSteps && currentStepIntentRequiresCapture === false;
  const isCancelVisible = !!status && ['COLLECTING', 'CHOOSE_READER', 'ERROR'].includes(status);
  const isFooterVisible = isCancelVisible || isConfirmVisible;
  const isSimulatedTerminal = DEVELOPMENT.USE_SIMULATED_STRIPE_TERMINAL;

  const {data: stripeLocationId} = useRecoilValue(stripeLocationIdState);

  const dialogInfo: TStripeDialogContent = useMemo(() => {
    switch (status) {
      case 'COLLECTING':
        return {
          dialogTitle: 'Terminal is waiting',
          variant: 'card',
          text: 'Waiting for client to tap or insert card',
          subtext: `Amount requested is ${amountOfCurrentStep}`
        };
      case 'CHOOSE_READER':
        return {
          dialogTitle: 'Choose terminal',
          variant: 'dialpad',
          text: 'Please select a terminal'
        };
      case 'LOADING':
        return {
          dialogTitle: 'Processing payment',
          variant: 'loading',
          text: loadingMessage ?? 'Please wait a few moments',
          subtext:
            terminalRef.current?.getPaymentStatus() === TERMINAL_PROCESSING_PAYMENT_STATUS
              ? 'Follow instructions on card reader, please do not refresh or exit the page'
              : undefined
        };
      case 'CANCELLING':
        return {
          dialogTitle: 'Cancelling payment',
          variant: 'loading',
          text: 'Cancelling the payment, please wait'
        };
      case 'ERROR':
        return {
          dialogTitle: 'Payment failed',
          variant: 'error',
          text: errorMessage ?? 'Payment was unsuccessful. Please try again.'
        };
    }
  }, [amountOfCurrentStep, errorMessage, loadingMessage, status]);

  const fetchConnectionToken = useCallback(async () => {
    const response = await paymentGatewayClient('/terminals/token');
    const data = response.data;
    return data.connectionToken;
  }, [paymentGatewayClient]);

  const initTerminalSDK = useCallback(async () => {
    const StripeTerminal = await loadStripeTerminal();
    terminalRef.current = StripeTerminal?.create({
      onFetchConnectionToken: fetchConnectionToken,
      onUnexpectedReaderDisconnect: () => {
        updateCurrentCardPaymentDialogState({
          status: 'ERROR',
          errorMessage: `Error: Connection lost with terminal`
        });
      }
    });
  }, [fetchConnectionToken, updateCurrentCardPaymentDialogState]);

  const discoverReaders = useCallback(async () => {
    if (!stripeLocationId) {
      console.error(PURCHASE_TERMINAL_INIT_ERROR, stripeLocationId);
      throw new Error(PURCHASE_TERMINAL_INIT_ERROR);
    }
    if (!terminalRef.current) return [];
    const config = {simulated: isSimulatedTerminal, location: stripeLocationId};
    const discoverResponse = await terminalRef.current.discoverReaders(config);
    const discoverError = discoverResponse as ErrorResponse;
    const discoverResult = discoverResponse as DiscoverResult;
    if (discoverError.error) throw new Error(discoverError.error.message ?? '');
    if (discoverResult.discoveredReaders.length === 0) throw new Error('No available readers.');
    return discoverResult.discoveredReaders ?? [];
  }, [isSimulatedTerminal, stripeLocationId]);

  const chooseReader = useCallback(
    (readers: Reader[]): Promise<Reader> => {
      return new Promise(resolve => {
        const onlineReaders = readers.filter(reader => reader.status === 'online');
        onlineReaders.length === 1
          ? resolve(onlineReaders[0])
          : updateCurrentCardPaymentDialogState({
              readers,
              onReaderSelect: (reader: Reader) => resolve(reader)
            });
      });
    },
    [updateCurrentCardPaymentDialogState]
  );

  const onSimulatorCardNumberSelect = useCallback(
    () =>
      new Promise(resolve =>
        updateCurrentCardPaymentDialogState({
          onCardNumberSelect: testCardNumber =>
            resolve(terminalRef.current?.setSimulatorConfiguration({testCardNumber}))
        })
      ),
    [updateCurrentCardPaymentDialogState]
  );

  const connectReader = useCallback(async (reader: Reader): Promise<void> => {
    const connectResponse = await terminalRef?.current?.connectReader(reader);
    const connectError = connectResponse as ErrorResponse;
    if (connectError.error) throw new Error(connectError.error.message ?? '');
  }, []);

  const resetReaderIfConnected = useCallback(async () => {
    // Errors here silent for now and not breaking the flow
    try {
      if (terminalRef?.current?.getPaymentStatus() === 'waiting_for_input')
        await terminalRef?.current?.cancelCollectPaymentMethod();
      if (terminalRef?.current?.getConnectedReader()) await terminalRef?.current?.disconnectReader();
    } catch {}
  }, []);

  const collectPaymentMethod = useCallback(
    async (clientSecret: string): Promise<void> => {
      if (isSimulatedTerminal) await onSimulatorCardNumberSelect();
      const collectResponse = await terminalRef?.current?.collectPaymentMethod(clientSecret);
      const collectError = collectResponse as ErrorResponse;
      const collectResult = collectResponse as {paymentIntent: ISdkManagedPaymentIntent};

      // If we cancel terminal collection while terminal is waiting for a card,
      // Stripe collectResponse will have a canceled error, but we don't think of it as an error,
      // we just want to close the dialog. Throwing the code (as opposed to the message) makes it
      // safer to handle downstream. (When this is internationalised the message could be in a different lang)
      const isCanceled = collectError?.error?.code === STRIPE_COLLECT_ERROR_CANCELED_CODE;
      if (isCanceled) throw new Error(STRIPE_COLLECT_ERROR_CANCELED_CODE);

      if (collectError.error) throw new Error(collectError.error.message ?? '');
      if (collectResult.paymentIntent.charges) console.log('Collect: warn charges');
      updateCurrentCardPaymentIntent(collectResult.paymentIntent);
    },
    [isSimulatedTerminal, onSimulatorCardNumberSelect, updateCurrentCardPaymentIntent]
  );

  const processPayment = useCallback(async (): Promise<void> => {
    const {currentStep} = getCardPaymentFlowInfo();
    const processResponse = await terminalRef?.current?.processPayment(
      currentStep?.paymentIntent as SdkManagedPaymentIntent
    );
    const processError = processResponse as ErrorResponse;
    const processResult = processResponse as {paymentIntent: ISdkManagedPaymentIntent};
    if (processError.error || !processResult.paymentIntent?.id)
      throw new Error(processError.error.message ?? 'There was an error processing the payment');
    updateCurrentCardPaymentIntent(processResult.paymentIntent);
  }, [getCardPaymentFlowInfo, updateCurrentCardPaymentIntent]);

  const onLocalSelectReader = useCallback(
    (reader: Reader) => {
      updateCurrentCardPaymentDialogState({
        readers: undefined,
        status: 'COLLECTING'
      });
      onReaderSelect?.(reader);
    },
    [onReaderSelect, updateCurrentCardPaymentDialogState]
  );

  const onLocalSelectCardNumber = useCallback(
    (testCardNumber: STRIPE_TEST_CARD_NUMBER) => {
      onCardNumberSelect?.(testCardNumber);
      updateCurrentCardPaymentDialogState({status: 'LOADING', loadingMessage: 'Processing payment'});
    },
    [onCardNumberSelect, updateCurrentCardPaymentDialogState]
  );

  const onClickDialogCancel = useCallback(async () => {
    updateCurrentCardPaymentDialogState({status: 'CANCELLING'});
    await Promise.allSettled([resetReaderIfConnected(), cancelFlowPayments()]);
  }, [cancelFlowPayments, resetReaderIfConnected, updateCurrentCardPaymentDialogState]);

  const attemptPaymentWithSelectedTerminal: IUseStripeTerminalFlowOutput['attemptPaymentWithSelectedTerminal'] =
    useCallback(
      async reader => {
        const {client_secret} = getCardPaymentFlowInfo().currentStep!.paymentIntent;
        if (!client_secret) throw new Error(PURCHASE_TERMINAL_INIT_ERROR);
        await connectReader(reader);
        updateCurrentCardPaymentDialogState({status: 'COLLECTING'});
        await collectPaymentMethod(client_secret);
        updateCurrentCardPaymentDialogState({status: 'LOADING', loadingMessage: 'Processing payment'});
        await processPayment();
      },
      [collectPaymentMethod, connectReader, getCardPaymentFlowInfo, processPayment, updateCurrentCardPaymentDialogState]
    );

  const runTerminalPayment: IUseStripeTerminalFlowOutput['runTerminalPayment'] = useCallback(async () => {
    const {currentStep} = getCardPaymentFlowInfo();
    const {amount, client_secret} = currentStep?.paymentIntent || {};
    if (!client_secret || !currentStep) return;
    updateCurrentCardPaymentDialogState({amount, status: 'LOADING', clientSecret: client_secret});
    await initTerminalSDK();
    const readers = await discoverReaders();
    if (!readers) throw new Error(`No terminals found.`);
    if (!isOnlyOneReaderOnline(readers)) updateCurrentCardPaymentDialogState({status: 'CHOOSE_READER', readers});
    const reader = await chooseReader(readers);
    updateCurrentCardPaymentDialogState({
      status: 'LOADING',
      loadingMessage: 'Connecting...',
      reader: reader
    });

    await attemptPaymentWithSelectedTerminal(reader);
  }, [
    updateCurrentCardPaymentDialogState,
    initTerminalSDK,
    getCardPaymentFlowInfo,
    discoverReaders,
    chooseReader,
    attemptPaymentWithSelectedTerminal
  ]);

  return {
    attemptPaymentWithSelectedTerminal,
    dialogInfo,
    resetReaderIfConnected,
    runTerminalPayment,
    isCancelVisible,
    isFooterVisible,
    isSimulatedTerminal,
    showFallbackOptions,
    onClickDialogCancel,
    onLocalSelectReader,
    onLocalSelectCardNumber,
    reader,
    readers,
    status
  };
};

export {useStripeTerminalFlow};

const isOnlyOneReaderOnline = (readers: Reader[]) => readers?.filter(reader => reader.status === 'online').length === 1;
